import React from 'react'
import { TextField, Button, Link } from '@deque/cauldron-react'
import FormTile from './FormTile'
import withClients, { IsClientProps } from './withClients'
import * as analytics from '../analytics'
import './Login.css'

interface Props extends IsClientProps {
  resourcePath: string
  submitText: string
  cancelText: string
  headingText: string
  oneTimeCodeText: string
  error?: string
  privacyPolicyText: string
  dequeHomeText: string
}

const OneTimeCode: React.ComponentType<Props> = ({
  isAxe,
  resourcePath,
  submitText,
  privacyPolicyText,
  dequeHomeText,
  headingText,
  oneTimeCodeText,
  error
}) => {
  const handleClick = (): void => {
    analytics.twoFactorClick({
      isAxe
    })
  }
  return (
    <div className="Login">
      <FormTile
        logo={<img src={`${resourcePath}/img/deque.svg`} alt="Deque" />}
        heading={<h1>{headingText}</h1>}
      >
        <TextField
          type="text"
          id="otp"
          name="otp"
          label={oneTimeCodeText}
          autoComplete="off"
          error={error}
          aria-invalid={!!error}
          required
        />
        <div className="App__tile-actions">
          <Button
            name="login"
            type="submit"
            onClick={handleClick}
            className="full-width"
          >
            {submitText}
          </Button>
        </div>
        <hr className="App__tile-hr--fade" />
        <ul className="App__tile-foot">
          <li>
            <Link
              href="https://www.deque.com/privacy-policy/"
              rel="noopener noreferrer"
            >
              {privacyPolicyText}
            </Link>
          </li>
          <li>
            <Link href="https://deque.com" rel="noopener noreferrer">
              {dequeHomeText}
            </Link>
          </li>
        </ul>
      </FormTile>
    </div>
  )
}

OneTimeCode.displayName = 'OneTimeCode'

export default withClients(OneTimeCode)
