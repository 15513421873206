import React, { useState, Fragment } from 'react'
import { TextField } from '@deque/cauldron-react'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string
  label: string
  error?: string
  fieldRef: (ref: HTMLInputElement) => void
}

const EmailField: React.ComponentType<Props> = ({
  value,
  label,
  error,
  fieldRef,
  ...other
}) => {
  const [emailValue, setEmailValue] = useState(value)

  return (
    <Fragment>
      <input type="hidden" id="username" name="username" value={emailValue} />
      <TextField
        required
        type="text"
        id="email"
        name="email"
        label={label}
        autoComplete="email"
        error={error}
        fieldRef={fieldRef}
        {...other}
        defaultValue={emailValue}
        onChange={value => setEmailValue(value)}
      />
    </Fragment>
  )
}

EmailField.displayName = 'EmailField'

export default EmailField
