import React from 'react'

interface Props {
  headingText: string
  subheadingText: string
  paragraphText: string
  resourcePath: string
}

const ErrorPage: React.ComponentType<Props> = ({
  headingText,
  subheadingText,
  paragraphText,
  resourcePath
}) => (
  <div className="App">
    <div className="App__tile">
      <img src={`${resourcePath}/img/deque.svg`} alt="Deque" />
      <h1>{headingText}</h1>
      <h2>{subheadingText}</h2>
      <p>{paragraphText}</p>
    </div>
  </div>
)

ErrorPage.displayName = 'ErrorPage'

export default ErrorPage
