import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import { render } from 'react-dom'
import whenReady from 'when-dom-ready'
import {
  UNSET_AXE_SERVER_URL,
  UNSET_DEPLOY_ENV,
  UNSET_AXE_CLIENT_ID,
  UNSET_DQU_CLIENT_ID,
  UNSET_MOBILE_CLIENT_ID,
  UNSET_THEME_CLIENT_ID,
  DEFAULT_AXE_CLIENT_ID,
  DEFAULT_DQU_CLIENT_ID,
  DEFAULT_MOBILE_CLIENT_ID,
  DEFAULT_THEME_CLIENT_ID,
  UNSET_SUPPORT_DOMAIN,
  DEFAULT_SUPPORT_DOMAIN
} from '../constants'
import * as analytics from '../analytics'
import Wrapper from '../Wrapper'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import UpdatePassword from './UpdatePassword'
import ErrorPage from './ErrorPage'
import SetupOneTimePassword from './SetupOneTimePassword'
import OneTimeCode from './OneTimeCode'
import UpdateProfile from './UpdateProfile'
import VerifyEmail from './VerifyEmail'
import IDPLinkConfirm from './IDPLinkConfirm'
import IDPLinkEmail from './IDPLinkEmail'
import Register from './Register'
import Info from './Info'

const renderLoginApp = () => {
  const errorPageMount = document.getElementById('error-page')
  if (errorPageMount) {
    const data = errorPageMount.dataset
    render(
      <ErrorPage
        headingText={data.headingText}
        subheadingText={data.subheadingText}
        paragraphText={data.paragraphText}
        resourcePath={data.resourcePath}
      />,
      errorPageMount
    )
    return
  }

  const form: HTMLFormElement = document.querySelector('.deque-standalone-form')
  const alertError: HTMLElement = document.querySelector(
    '.alert.alert-error .kc-feedback-text'
  )
  const otpErrorElement: HTMLElement = document.getElementById(
    'input-error-otp-code'
  )
  const alertSuccess: HTMLElement = document.querySelector(
    '.alert.alert-success .kc-feedback-text'
  )

  /**
   * If none of the forms exist, just bail and let the base template take over
   */
  if (!form) {
    return
  }

  let pageViewName = ''
  let innerForm: React.ReactElement | null = null

  const data = form.dataset
  const documentTitle = data.documentTitle || document.title
  const error = alertError && alertError.innerText
  const otpError = otpErrorElement && otpErrorElement.innerText

  analytics.init({
    amplitudeApiKey: process.env.AMPLITUDE_API_KEY
  })

  // The environment we're deployed to (local, dev, qa, prod).
  const deployEnv =
    data.deploy_env === UNSET_DEPLOY_ENV ? 'local' : data.deployEnv

  // Known (public) client IDs. We use these to determine wether or not to render self registration links.
  const mobileClientID =
    data.mobileClientId === UNSET_MOBILE_CLIENT_ID
      ? DEFAULT_MOBILE_CLIENT_ID
      : data.mobileClientId
  const axeClientID =
    data.axeClientId === UNSET_AXE_CLIENT_ID
      ? DEFAULT_AXE_CLIENT_ID
      : data.axeClientId
  const dquClientID =
    data.dquClientId === UNSET_DQU_CLIENT_ID
      ? DEFAULT_DQU_CLIENT_ID
      : data.dquClientId
  const themeClientID = (data.themeClientId = UNSET_THEME_CLIENT_ID
    ? DEFAULT_THEME_CLIENT_ID
    : data.themeClientId)

  const supportDomain =
    data.supportDomain == UNSET_SUPPORT_DOMAIN
      ? DEFAULT_SUPPORT_DOMAIN
      : data.supportDomain

  // The address to the axe server.
  let axeServerURL: string
  if (data.axeServerURL === UNSET_AXE_SERVER_URL) {
    if (deployEnv === 'local') {
      axeServerURL = 'http://localhost:3000'
    } else if (deployEnv === 'dev') {
      axeServerURL = 'https://axe.dequelabs.com'
    } else if (deployEnv === 'qa') {
      axeServerURL = 'https://axe-qa.dequelabs.com'
    } else if (deployEnv === 'prod') {
      axeServerURL = 'https://axe.deque.com'
    } else {
      // When $DEPLOY_ENV is invalid and there's no $AXE_SERVER_URL, there's nothing we can do.
      throw new Error(`Invalid $DEPLOY_ENV variable: "${deployEnv}"`)
    }
  } else {
    axeServerURL = data.axeServerUrl
  }

  /**
   * Routing based on the form's id since we have full control over what is rendered (via freemarker templates)
   */
  switch (form.id) {
    case 'kc-form-login': {
      const registrationEnabled = data.registrationEnabled === 'true'
      innerForm = (
        <Login
          axeServerURL={axeServerURL}
          axeClientID={axeClientID}
          dquClientID={dquClientID}
          mobileClientID={mobileClientID}
          themeClientID={themeClientID}
          supportDomain={supportDomain}
          resourcePath={data.resourcePath}
          usernameText={data.usernameText}
          passwordText={data.passwordText}
          submitText={data.submitText}
          nextText={data.nextText}
          registerHeadingText={data.headingText}
          noRegisterHeadingText={data.noRegisterHeadingText}
          loginText={data.loginText}
          forgotPasswordText={data.forgotPasswordText}
          forgotPasswordUrl={data.forgotPasswordUrl}
          forgotPasswordSuccessHeading={data.forgotPasswordSuccessHeading}
          forgotPasswordSuccessMessage={data.forgotPasswordSuccessMessage}
          forgotPasswordSuccessRedirect={data.forgotPasswordSuccessRedirect}
          registerUrl={data.registerUrl}
          registerText={data.registerText}
          registrationEnabled={registrationEnabled}
          alertSuccess={alertSuccess && alertSuccess.innerText}
          rememberMeEnabled={data.rememberMeEnabled === 'true'}
          rememberMeChecked={data.rememberMeChecked === 'true'}
          rememberMeText={data.rememberMeText}
          forgotPasswordEnabled={data.forgotPasswordEnabled === 'true'}
          loadingText={data.loadingText}
          socialProviders={
            data.socialProviders && JSON.parse(data.socialProviders)
          }
          privacyPolicyText={data.privacyPolicyText}
          userNoticeText={data.userNoticeText}
          dequeHomeText={data.dequeHomeText}
          cancelText={data.cancelText}
          acceptInvitationText={data.acceptInvitationText}
          completePurchaseText={data.completePurchaseText}
          error={error}
        />
      )
      pageViewName = 'login'
      break
    }
    case 'kc-reset-password-form':
      innerForm = (
        <ForgotPassword
          error={error}
          resourcePath={data.resourcePath}
          submitText={data.submitText}
          loginUrl={data.loginUrl}
          usernameText={data.usernameText}
          cancelText={data.cancelText}
          headingText={data.headingText}
        />
      )
      pageViewName = 'forgot-password'
      break
    case 'kc-passwd-update-form':
      innerForm = (
        <UpdatePassword
          axeClientID={axeClientID}
          dquClientID={dquClientID}
          mobileClientID={mobileClientID}
          themeClientID={themeClientID}
          supportDomain={supportDomain}
          error={error}
          resourcePath={data.resourcePath}
          submitText={data.submitText}
          headingText={data.headingText}
          passwordNewText={data.passwordNewText}
          passwordConfirmText={data.passwordConfirmText}
          paragraphText={data.paragraphText}
        />
      )
      pageViewName = 'update-password'
      break
    case 'kc-totp-settings-form':
      innerForm = (
        <SetupOneTimePassword
          axeClientID={axeClientID}
          dquClientID={dquClientID}
          mobileClientID={mobileClientID}
          themeClientID={themeClientID}
          supportDomain={supportDomain}
          error={error}
          headingText={data.headingText}
          inputLabel={data.inputLabel}
          step1Text={data.step1Text}
          step2Text={data.step2Text}
          step3Text={data.step3Text}
          submitText={data.submitText}
          totpSecret={data.totpSecret}
          resourcePath={data.resourcePath}
          barcodeImage={data.barcodeImage}
          unableToScanText={data.unableToScanText}
          unableToScanHref={data.unableToScanHref}
          barcodeImageAlt={data.barcodeImageAlt}
          authenticatorApplications={data.authenticatorApplications}
        />
      )
      pageViewName = 'setup-totp'
      break
    case 'kc-totp-login-form':
      innerForm = (
        <OneTimeCode
          axeClientID={axeClientID}
          dquClientID={dquClientID}
          mobileClientID={mobileClientID}
          themeClientID={themeClientID}
          supportDomain={supportDomain}
          error={otpError}
          resourcePath={data.resourcePath}
          submitText={data.submitText}
          cancelText={data.cancelText}
          headingText={data.headingText}
          oneTimeCodeText={data.oneTimeCodeText}
          privacyPolicyText={data.privacyPolicyText}
          dequeHomeText={data.dequeHomeText}
        />
      )
      pageViewName = 'one-time-code'
      break
    case 'kc-update-profile-form':
      innerForm = (
        <UpdateProfile
          axeClientID={axeClientID}
          dquClientID={dquClientID}
          mobileClientID={mobileClientID}
          themeClientID={themeClientID}
          supportDomain={supportDomain}
          errors={error && error.split('<br>')}
          resourcePath={data.resourcePath}
          submitText={data.submitText}
          headingText={data.headingText}
          emailValue={data.emailValue}
          emailText={data.emailText}
          emailErrorText={data.emailErrorText}
          invalidEmailErrorText={data.invalidEmailErrorText}
          lastNameText={data.lastNameText}
          firstNameText={data.firstNameText}
          firstNameErrorText={data.firstNameErrorText}
          lastNameErrorText={data.lastNameErrorText}
          firstNameValue={data.firstNameValue}
          lastNameValue={data.lastNameValue}
        />
      )
      pageViewName = 'update-profile'
      break
    case 'login-verify-email-form':
      innerForm = (
        <VerifyEmail
          axeClientID={axeClientID}
          dquClientID={dquClientID}
          mobileClientID={mobileClientID}
          themeClientID={themeClientID}
          supportDomain={supportDomain}
          headingText={data.headingText}
          instructionsText={data.instructionsText}
          questionText={data.questionText}
          linkText={data.linkText}
          resourcePath={data.resourcePath}
          linkHref={data.linkHref}
        />
      )
      pageViewName = 'verify-email'
      break
    case 'kc-register-form':
      innerForm = (
        <IDPLinkConfirm
          axeClientID={axeClientID}
          dquClientID={dquClientID}
          mobileClientID={mobileClientID}
          themeClientID={themeClientID}
          supportDomain={supportDomain}
          headingText={data.headingText}
          resourcePath={data.resourcePath}
          updateProfileText={data.updateProfileText}
          linkAccountText={data.linkAccountText}
          linkAccountInfoText={data.linkAccountInfoText}
        />
      )
      pageViewName = 'idp-link-confirm'
      break
    case 'login-idp-link-email':
      innerForm = (
        <IDPLinkEmail
          headingText={data.headingText}
          resourcePath={data.resourcePath}
          instructionsText={data.instructionsText}
          resendEmailText={data.resendEmailText}
          resendEmailLinkText={data.resendEmailLinkText}
          alreadyVerifiedText={data.alreadyVerifiedText}
          alreadyVerifiedLinkText={data.alreadyVerifiedLinkText}
          linkHref={data.linkHref}
        />
      )
      pageViewName = 'idp-link-email'
      break
    case 'kc-self-register-form':
      innerForm = (
        <Register
          resourcePath={data.resourcePath}
          headingText={data.headingText}
          firstNameLabel={data.firstNameLabel}
          firstNameValue={data.firstNameValue}
          lastNameLabel={data.lastNameLabel}
          lastNameValue={data.lastNameValue}
          emailLabel={data.emailLabel}
          emailValue={data.emailValue}
          passwordLabel={data.passwordLabel}
          confirmPasswordLabel={data.confirmPasswordLabel}
          backToLoginText={data.backToLoginText}
          backToLoginHref={data.backToLoginHref}
          submitText={data.submitText}
          firstNameErrorText={data.firstNameErrorText}
          lastNameErrorText={data.lastNameErrorText}
          emailErrorText={data.emailErrorText}
          invalidEmailErrorText={data.invalidEmailErrorText}
          missingPasswordErrorText={data.missingPasswordErrorText}
          passwordMatchErrorText={data.passwordMatchErrorText}
          passwordConfirmMatchErrorText={data.passwordConfirmMatchErrorText}
          usernameExistsErrorText={data.usernameExistsErrorText}
          emailExistsErrorText={data.emailExistsErrorText}
          errors={error && error.split('<br>')}
        />
      )
      pageViewName = 'self-registration'
      break
    case 'kc-confirmation-form':
      innerForm = (
        <Info
          resourcePath={data.resourcePath}
          heading={data.heading}
          requiredActions={
            data.requiredActions && JSON.parse(data.requiredActions)
          }
          verifyEmailText={data.verifyEmailText}
          verifyEmailMessage={data.verifyEmailMessage}
          actionHref={data.actionHref}
          actionText={data.actionText}
        />
      )
      pageViewName = 'info'
  }

  render(<Wrapper title={documentTitle}>{innerForm}</Wrapper>, form)
}

whenReady().then(renderLoginApp)
