import React, { Component, Fragment } from 'react'
import * as url from 'url'
import {
  TextField,
  Button,
  Link,
  Checkbox,
  Loader,
  Toast
} from '@deque/cauldron-react'
import classNames from 'classnames'
import queryString from 'query-string'
import Keycloak from 'keycloak-js'
import FormTile from './FormTile'
import * as analytics from '../analytics'
import {
  EMAIL_LOCAL_STORAGE_KEY,
  REDIRECT_NOTICE_TIMEOUT,
  REDIRECT_URI_LOCAL_STORAGE_KEY
} from '../constants'
import withClients, { IsClientProps } from './withClients'
import './Login.css'
import { parse as urlParse } from 'tldts'

interface SocialProvider {
  url: string
  displayName: string
  displayNameText: string
  alias: string
  aliasText: string
}

interface Props extends IsClientProps {
  axeServerURL: string
  resourcePath: string
  usernameText: string
  passwordText: string
  submitText: string
  nextText: string
  registerHeadingText: string
  noRegisterHeadingText: string
  forgotPasswordText: string
  forgotPasswordUrl: string
  forgotPasswordSuccessHeading: string
  forgotPasswordSuccessMessage: string
  forgotPasswordSuccessRedirect: string
  registerUrl: string
  registerText: string
  registrationEnabled: boolean
  alertSuccess?: string
  rememberMeEnabled: boolean
  rememberMeChecked: boolean
  rememberMeText: string
  forgotPasswordEnabled: boolean
  loadingText: string
  socialProviders: SocialProvider[]
  loginText: string
  privacyPolicyText: string
  userNoticeText: string
  dequeHomeText: string
  cancelText: string
  acceptInvitationText: string
  completePurchaseText: string
  error?: string
}

interface State {
  loading: boolean
  showSuccess: boolean
  email?: string
  emailAnimationClass: string
  passwordAnimationClass: string
  showInvitationToast: boolean
  showPurchaseToast: boolean
}

interface RedirectURIParams {
  socialProvider?: string
}

const sleep = (ms = 0) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const socialProviderMap = {
  google: 'google.svg',
  github: 'github.svg',
  linkedin: 'linkedin.svg'
}

export class Login extends Component<Props, State> {
  static defaultProps = {
    error: null
  }

  state = {
    loading: false,
    showSuccess: false,
    email: '',
    emailAnimationClass: '',
    passwordAnimationClass: '',
    showInvitationToast: false,
    showPurchaseToast: false
  }

  private keycloak: Keycloak.KeycloakInstance
  private loader: HTMLDivElement | null = null
  private usernameInput: HTMLInputElement | null = null
  private passwordInput: HTMLInputElement | null = null
  private registrationURL = ''

  private setUsernameInput = (el: HTMLInputElement): void => {
    this.usernameInput = el
  }

  private setPasswordInput = (el: HTMLInputElement): void => {
    this.passwordInput = el
  }

  private showPassword = async (email: string): Promise<void> => {
    this.setState({
      email,
      passwordAnimationClass: 'Password--slide-in',
      emailAnimationClass: 'Email--slide-out'
    })
    this.setState(
      {
        emailAnimationClass: 'Email--slide-out Email--hidden'
      },
      async () => {
        if (!this.passwordInput) {
          return
        }
        // wait (350ms) for transition to complete because focusing too early ruins the animation
        await sleep(350)
        this.passwordInput.focus()
      }
    )
  }

  private onNextClick = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    const email = this.usernameInput.value
    const [, domain] = email.split('@')
    // very cheap email validation because we mostly just let keycloak handle that
    const invalidEmail = !email || !domain
    const parsedUrl = urlParse(domain)
    const analyticsEvent = this.state.passwordAnimationClass
      ? analytics.loginSubmitClick
      : analytics.loginNextClick
    analyticsEvent({
      isAxe: this.props.isAxe
    })

    // just let keycloak handle the form submission for the 2nd step (password)
    // and let keycloak handle the form submission for empty/invalid email field (1st step)
    if (this.state.passwordAnimationClass || invalidEmail) {
      return
    }

    e.preventDefault()

    // NOTE: `RelayState` is the saml equivalent of oauth's `redirect_uri`
    const { redirect_uri, RelayState } = queryString.parse(location.search)
    let redirectURI = redirect_uri || RelayState

    if (redirectURI) {
      // Persist the redirect uri in local storage. This is needed
      // because keycloak removes the redirect uri query param from
      // the url when the form is in an error state (like wrong password)
      localStorage.setItem(
        REDIRECT_URI_LOCAL_STORAGE_KEY,
        redirectURI as string
      )
    } else {
      // We're presumably in an error state in which keycloak has removed
      // the redirect uri from the url. Read the cached uri from localStorage
      // NOTE: it shouldn't be possible to be in this state without a value in localStorage
      redirectURI = localStorage.getItem(REDIRECT_URI_LOCAL_STORAGE_KEY)
    }

    this.setState(
      {
        loading: true
      },
      () => this.loader?.focus()
    )

    try {
      // the hidden idp lookup/redirect doesn't work when user is logging into security-admin-console
      if (!this.props.isAdminConsole) {
        await this.keycloak.init({
          checkLoginIframe: false,
          redirectUri: redirectURI as string
        })

        let loginURL = this.keycloak.createLoginUrl({
          idpHint: domain,
          redirectUri: redirectURI as string
        })
        let url = `https://${loginURL}`
        const res = await fetch(url, { redirect: 'manual' })
        let hasAssociatedIDP = res.status !== 200

        if (!hasAssociatedIDP && parsedUrl.subdomain) {
          // If no associated IDP, and the domain has a subdomain, try the parent domain
          loginURL = this.keycloak.createLoginUrl({
            idpHint: `.${parsedUrl.domain}`,
            redirectUri: redirectURI as string
          })
          url = `https://${loginURL}`
          const domainRes = await fetch(url, { redirect: 'manual' })
          hasAssociatedIDP = domainRes.status !== 200
        }

        if (hasAssociatedIDP) {
          location.href = url // redirect to IDP
          return
        }
      }
    } catch (err) {
      // keycloak init failed. gracefully fallback to non-idp login
    } finally {
      this.setState({ loading: false })
    }

    localStorage.setItem(EMAIL_LOCAL_STORAGE_KEY, email)
    this.showPassword(email)
  }

  private onDifferentAccountClick = (): void => {
    this.setState(
      {
        passwordAnimationClass: 'Password--slide-out',
        emailAnimationClass: 'Email--slide-in'
      },
      async () => {
        await sleep(350) // wait for css animation to complete
        this.setState({
          passwordAnimationClass: '',
          emailAnimationClass: ''
        })

        this.usernameInput?.focus()
      }
    )
  }

  private getRegistrationURL() {
    const { redirect_uri = '', RelayState } = queryString.parse(location.search)
    const {
      axeServerURL,
      registerUrl,
      isAxe,
      isMobile,
      isUniversity,
      isAxeOnPrem,
      isSupportDomain
    } = this.props

    // Neither axe.mycompany.org/signup nor self-registration are enabled for on-premises deployments, or other unknown clients.
    if (
      isAxeOnPrem ||
      (!isAxe && !isMobile && !isUniversity && !isSupportDomain)
    ) {
      return null
    }

    let registrationURL = url.resolve(axeServerURL, '/signup')
    // DQU allows self-registration.
    if (isUniversity && registerUrl) {
      registrationURL = registerUrl
    }

    const query: Record<string, string> = {}
    if (isMobile) {
      query.product = 'axe-devtools-mobile'
    }
    if (isSupportDomain) {
      query.redirect_uri = RelayState as string
    } else {
      query.redirect_uri = redirect_uri as string
    }

    return `${registrationURL}?${queryString.stringify(query)}`
  }

  private socialClickFactory(alias) {
    return (): void => {
      if (alias !== 'github' && alias !== 'google') {
        return
      }

      const analyticsEvent =
        alias === 'github' ? analytics.githubConnect : analytics.googleConnect

      analyticsEvent({
        isAxe: this.props.isAxe
      })
    }
  }

  private onSignupClick() {
    analytics.dequeSignup({
      isAxe: this.props.isAxe
    })
  }

  private onForgotPasswordClick() {
    analytics.forgotPasswordClick({
      isAxe: this.props.isAxe
    })
  }

  private handleProviderRedirect(redirect_uri) {
    if (!redirect_uri) {
      return
    }

    try {
      // handle "?socialProvider=google" query params within redirect uri
      const { socialProvider }: RedirectURIParams = queryString.parse(
        new URL(redirect_uri as string).search
      )
      const associatedProvider =
        socialProvider &&
        this.props.socialProviders?.find(
          provider =>
            provider.displayName.toLowerCase() === socialProvider.toLowerCase()
        )

      if (!associatedProvider) {
        return
      }

      location.href = `${location.origin}${associatedProvider.url}`
      // eslint-disable-next-line no-empty
    } catch (err) {} // silently fail
  }

  constructor(props: Props) {
    super(props)
    const { redirect_uri } = queryString.parse(location.search)
    this.registrationURL = this.getRegistrationURL()

    this.handleProviderRedirect(redirect_uri)

    const pathParts = location.pathname.split('/')
    // The realm is in the 3rd position of the path.
    // For example, in "/auth/realms/axe/protocol/openid-connect/auth", the realm is "axe".
    const [, , , realm] = pathParts
    this.keycloak = Keycloak({
      realm,
      url: `${location.host}/auth/`,
      clientId: this.props.themeClientID
    })
  }

  public componentDidMount(): void {
    const { error, alertSuccess } = this.props
    if (error && this.usernameInput) {
      this.usernameInput.focus()
    }

    analytics.loginView({
      isAxe: this.props.isAxe
    })

    try {
      const { redirect_uri } = queryString.parse(location.search)
      const redirectURL = redirect_uri && new URL(redirect_uri as string)

      if (redirectURL?.pathname.startsWith('/accept-invitation')) {
        this.setState({
          showInvitationToast: true
        })
      } else if (redirectURL?.pathname.startsWith('/purchase')) {
        this.setState({
          showPurchaseToast: true
        })
      }
      // eslint-disable-next-line no-empty
    } catch (err) {} // silently fail (when redirect uri is not a valid url)

    if (!alertSuccess) {
      return
    }

    this.setState({
      showSuccess: true,
      email: localStorage.getItem(EMAIL_LOCAL_STORAGE_KEY)
    })

    // auto-hide the success message after REDIRECT_NOTICE_TIMEOUT (ms)
    setTimeout(() => {
      this.setState(
        {
          showSuccess: false
        },
        () => {
          this.usernameInput?.focus()
        }
      )
    }, REDIRECT_NOTICE_TIMEOUT)
    localStorage.removeItem(EMAIL_LOCAL_STORAGE_KEY)
  }

  onAcceptInvitationToastClose = (): void => {
    this.setState({
      showInvitationToast: false
    })
  }

  onPurchaseToastClose = (): void => {
    this.setState({
      showPurchaseToast: false
    })
  }

  public render(): React.ReactElement {
    const {
      showSuccess,
      email,
      passwordAnimationClass,
      emailAnimationClass,
      showInvitationToast,
      showPurchaseToast,
      loading
    } = this.state
    const {
      resourcePath,
      usernameText,
      passwordText,
      submitText,
      nextText,
      registerHeadingText,
      noRegisterHeadingText,
      registerText,
      registrationEnabled,
      forgotPasswordText,
      forgotPasswordUrl,
      forgotPasswordSuccessHeading,
      forgotPasswordSuccessMessage,
      forgotPasswordSuccessRedirect,
      rememberMeEnabled,
      rememberMeChecked,
      rememberMeText,
      forgotPasswordEnabled,
      loadingText,
      socialProviders,
      loginText,
      privacyPolicyText,
      dequeHomeText,
      cancelText,
      acceptInvitationText,
      completePurchaseText,
      error
    } = this.props

    let headText = noRegisterHeadingText

    if (registrationEnabled && this.registrationURL) {
      headText = registerHeadingText
    } else if (showSuccess) {
      forgotPasswordSuccessHeading
    }

    return (
      <div className="Login">
        {this.registrationURL && showInvitationToast && acceptInvitationText && (
          <Toast show type="info" onDismiss={this.onAcceptInvitationToastClose}>
            {acceptInvitationText}
          </Toast>
        )}

        {this.registrationURL && showPurchaseToast && completePurchaseText && (
          <Toast show type="info" onDismiss={this.onPurchaseToastClose}>
            {completePurchaseText}
          </Toast>
        )}

        <FormTile
          logo={<img src={`${resourcePath}/img/deque.svg`} alt="Deque" />}
          heading={<h1>{headText}</h1>}
          className={classNames('Email', emailAnimationClass)}
        >
          {showSuccess ? (
            <div className="App__tile-msg">
              <code>{email}</code>
              <div role="alert">
                <p>{forgotPasswordSuccessMessage}</p>
                <em>{forgotPasswordSuccessRedirect}</em>
              </div>
            </div>
          ) : (
            <Fragment>
              {loading && (
                <div tabIndex={-1} ref={el => (this.loader = el)}>
                  <Loader label={loadingText} />
                </div>
              )}
              {socialProviders &&
                socialProviders.map(
                  ({ displayName, displayNameText, aliasText, url, alias }) => (
                    <a
                      key={url}
                      className="App__social-link"
                      onClick={this.socialClickFactory(alias)}
                      href={url}
                    >
                      <img
                        src={`${resourcePath}/img/${socialProviderMap[alias] ||
                          'fallback.svg'}`}
                        alt=""
                      />
                      <span>{displayName ? displayNameText : aliasText}</span>
                    </a>
                  )
                )}
              <hr className="App__tile-hr--dot" />
              <h2>{loginText}</h2>
              <TextField
                id="username"
                name="username"
                label={usernameText}
                error={error}
                fieldRef={this.setUsernameInput}
                autoComplete="email"
              />
              {rememberMeEnabled && (
                <div className="App__tile-links">
                  <Checkbox
                    id="rememberMe"
                    name="rememberMe"
                    label={rememberMeText}
                    value=""
                    checked={rememberMeChecked}
                  />
                </div>
              )}
              <Button type="submit" onClick={this.onNextClick}>
                {nextText}
              </Button>
              <div className="App__tile-links--bottom">
                {registrationEnabled && this.registrationURL && (
                  <div className="Align--left">
                    <Link
                      className="App__tile-link--registration"
                      onClick={this.onSignupClick}
                      href={this.registrationURL}
                    >
                      {registerText}
                    </Link>
                  </div>
                )}
                {forgotPasswordEnabled && (
                  <div className="Align--left">
                    <Link
                      className="App__tile-link--forgot-password"
                      onClick={this.onForgotPasswordClick}
                      href={forgotPasswordUrl}
                    >
                      {forgotPasswordText}
                    </Link>
                  </div>
                )}
              </div>
              <hr className="App__tile-hr--fade" />
              <ul className="App__tile-foot">
                <li>
                  <Link href="https://www.deque.com/privacy-policy/">
                    {privacyPolicyText}
                  </Link>
                </li>
                <li>
                  <Link href="https://deque.com">{dequeHomeText}</Link>
                </li>
              </ul>
            </Fragment>
          )}
        </FormTile>
        <FormTile
          logo={<img src={`${resourcePath}/img/deque.svg`} alt="Deque" />}
          heading={<h1>{headText}</h1>}
          className={classNames('Password', passwordAnimationClass)}
        >
          <TextField
            readOnly
            id="readonly-email"
            name="readonly-email"
            label={usernameText}
            value={email}
          />
          <TextField
            id="password"
            name="password"
            label={passwordText}
            type="password"
            autoComplete="current-password"
            fieldRef={this.setPasswordInput}
          />
          {forgotPasswordEnabled && (
            <div className="App__tile-links">
              <Link
                onClick={this.onForgotPasswordClick}
                href={forgotPasswordUrl}
              >
                {forgotPasswordText}
              </Link>
            </div>
          )}
          <div className="App__tile-actions">
            <Button type="submit">{submitText}</Button>
            <Button variant="secondary" onClick={this.onDifferentAccountClick}>
              {cancelText}
            </Button>
          </div>
        </FormTile>
      </div>
    )
  }
}

export default withClients(Login)
