import React from 'react'
import { TextField, Button, Link } from '@deque/cauldron-react'
import FormTile from './FormTile'
import withClients, { IsClientProps } from './withClients'
import * as analytics from '../analytics'

interface Props extends IsClientProps {
  error?: string
  headingText: string
  inputLabel: string
  step1Text: string
  step2Text: string
  step3Text: string
  submitText: string
  totpSecret: string
  resourcePath: string
  barcodeImage: string
  unableToScanText: string
  unableToScanHref: string
  barcodeImageAlt: string
  authenticatorApplications: string
}

const SetupOneTimePassword: React.ComponentType<Props> = ({
  isAxe,
  error,
  headingText,
  inputLabel,
  step1Text,
  step2Text,
  barcodeImage,
  step3Text,
  submitText,
  totpSecret,
  resourcePath,
  unableToScanText,
  unableToScanHref,
  barcodeImageAlt,
  authenticatorApplications
}) => {
  const handleClick = (): void => {
    analytics.setupTwoFactorClick({
      isAxe
    })
  }
  return (
    <FormTile
      logo={<img src={`${resourcePath}/img/deque.svg`} alt="Deque" />}
      heading={<h1>{headingText}</h1>}
    >
      <ol>
        <li>
          <p>{step1Text}</p>
          <ul>
            {authenticatorApplications
              .split(',')
              .map(s => s.trim())
              .map(app => (
                <li key={app}>{app}</li>
              ))}
          </ul>
        </li>
        <li>
          <p>{step2Text}</p>
          <img
            id="kc-totp-secret-qr-code"
            src={barcodeImage}
            alt={barcodeImageAlt}
          />
          <Link href={unableToScanHref} id="mode-manual">
            {unableToScanText}
          </Link>
        </li>
        <li>
          <p>{step3Text}</p>
          <TextField
            required
            autoComplete="off"
            id="totp"
            name="totp"
            label={inputLabel}
            error={error}
          />
          <input
            type="hidden"
            id="totpSecret"
            name="totpSecret"
            value={totpSecret}
          />
        </li>
      </ol>
      <Button type="submit" onClick={handleClick}>
        {submitText}
      </Button>
    </FormTile>
  )
}

export default withClients(SetupOneTimePassword)
