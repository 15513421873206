import React, { Component } from 'react'
import { TextField, Button } from '@deque/cauldron-react'
import FormTile from './FormTile'
import EmailField from '../EmailField'
import withClients, { IsClientProps } from './withClients'
import * as analytics from '../analytics'

interface Props extends IsClientProps {
  resourcePath: string
  submitText: string
  headingText: string
  emailValue: string
  emailText: string
  emailErrorText: string
  invalidEmailErrorText: string
  lastNameText: string
  firstNameText: string
  firstNameErrorText: string
  lastNameErrorText: string
  firstNameValue?: string
  lastNameValue?: string
  errors?: string[]
}

interface AdditionalEmailProps {
  readOnly?: boolean
}

class UpdateProfile extends Component<Props> {
  private emailInput: HTMLInputElement | null = null
  private firstNameInput: HTMLInputElement | null = null
  private lastNameInput: HTMLInputElement | null = null

  private setEmailInput = (el: HTMLInputElement): void => {
    this.emailInput = el
  }
  private setFirstNameInput = (el: HTMLInputElement): void => {
    this.firstNameInput = el
  }
  private setLastNameInput = (el: HTMLInputElement): void => {
    this.lastNameInput = el
  }

  public componentDidMount(): void {
    const { errors } = this.props

    if (!errors || !errors.length) {
      return
    }

    // NOTE: order matters here
    const inputs = [this.emailInput, this.firstNameInput, this.lastNameInput]
    const firstInvalid = inputs.find(
      input => input && input.getAttribute('aria-invalid') === 'true'
    )

    if (!firstInvalid) {
      return
    }

    // focus the first erroneous input
    firstInvalid.focus()
  }

  private handleClick = (): void => {
    analytics.updateProfileClick({
      isAxe: this.props.isAxe
    })
  }

  public render(): React.ReactElement {
    const {
      resourcePath,
      submitText,
      headingText,
      emailValue,
      emailText,
      emailErrorText,
      invalidEmailErrorText,
      firstNameValue,
      firstNameText,
      firstNameErrorText,
      lastNameValue,
      lastNameText,
      lastNameErrorText,
      errors
    } = this.props

    const additionalEmailProps: AdditionalEmailProps = {}

    if (emailValue) {
      additionalEmailProps.readOnly = true
    }

    return (
      <FormTile
        logo={<img src={`${resourcePath}/img/deque.svg`} alt="Deque" />}
        heading={<h1>{headingText}</h1>}
      >
        <EmailField
          value={emailValue}
          label={emailText}
          error={
            (errors && errors.includes(emailErrorText) && emailErrorText) ||
            (errors &&
              errors.includes(invalidEmailErrorText) &&
              invalidEmailErrorText)
          }
          fieldRef={this.setEmailInput}
          autoComplete="off"
          {...additionalEmailProps}
        />
        <TextField
          defaultValue={firstNameValue}
          type="text"
          id="firstName"
          name="firstName"
          label={firstNameText}
          required
          autoComplete="given-name"
          error={
            errors && errors.includes(firstNameErrorText) && firstNameErrorText
          }
          fieldRef={this.setFirstNameInput}
        />
        <TextField
          defaultValue={lastNameValue}
          type="text"
          id="lastName"
          name="lastName"
          label={lastNameText}
          required
          autoComplete="family-name"
          error={
            errors && errors.includes(lastNameErrorText) && lastNameErrorText
          }
          fieldRef={this.setLastNameInput}
        />
        <Button type="submit" onClick={this.handleClick}>
          {submitText}
        </Button>
      </FormTile>
    )
  }
}

export default withClients(UpdateProfile)
