import React from 'react'
import queryString from 'query-string'
import { AXE_ON_PREM_CLIENT_ID, ADMIN_CONSOLE_CLIENT_ID } from '../constants'

interface withClientIdsProps {
  axeClientID: string
  mobileClientID: string
  dquClientID: string
  themeClientID: string
  supportDomain: string
}

export interface IsClientProps {
  isAxe: boolean
  isMobile: boolean
  isUniversity: boolean
  isAxeOnPrem: boolean
  isAdminConsole: boolean
  clientId: string
  themeClientID: string
  isSupportDomain: boolean
}

export default function withClientIds<T extends IsClientProps>(
  WrappedComponent: React.ComponentType<T>
): React.ComponentType<Omit<T, keyof IsClientProps> & withClientIdsProps> {
  return function ComponentWithClientIds({
    axeClientID,
    mobileClientID,
    dquClientID,
    themeClientID,
    supportDomain,
    ...props
  }): JSX.Element {
    const { client_id = '', RelayState } = queryString.parse(location.search)
    const isMobile = client_id === mobileClientID
    const isAxe = client_id === axeClientID
    const isUniversity = client_id === dquClientID
    const isAxeOnPrem = client_id === AXE_ON_PREM_CLIENT_ID
    let relayHostName = ''
    try {
      relayHostName = new URL(RelayState as string).hostname
    } catch {
      // do nothing if the url is invalid
    }
    const isSupportDomain = relayHostName === supportDomain
    const isAdminConsole = client_id === ADMIN_CONSOLE_CLIENT_ID
    return (
      // @ts-expect-error
      // This should be sufficiently typed to not include IsClientProps
      <WrappedComponent
        isAxe={isAxe}
        isMobile={isMobile}
        isUniversity={isUniversity}
        isAxeOnPrem={isAxeOnPrem}
        isAdminConsole={isAdminConsole}
        clientId={client_id as string}
        themeClientID={themeClientID}
        isSupportDomain={isSupportDomain}
        {...props}
      />
    )
  }
}
