import React from 'react'
import FormTile from './FormTile'
import { Link } from '@deque/cauldron-react'

interface Props {
  headingText: string
  resourcePath: string
  instructionsText: string
  resendEmailText: string
  resendEmailLinkText: string
  alreadyVerifiedText: string
  alreadyVerifiedLinkText: string
  linkHref: string
}

const IDPLinkEmail: React.ComponentType<Props> = ({
  headingText,
  resourcePath,
  instructionsText,
  resendEmailText,
  resendEmailLinkText,
  alreadyVerifiedText,
  alreadyVerifiedLinkText,
  linkHref
}) => (
  <FormTile
    logo={<img src={`${resourcePath}/img/deque.svg`} alt="Deque" />}
    heading={<h1>{headingText}</h1>}
  >
    <p>{instructionsText}</p>
    <p>
      {resendEmailText} <Link href={linkHref}>{resendEmailLinkText}</Link>
    </p>
    <p>
      {alreadyVerifiedText}{' '}
      <Link href={linkHref}>{alreadyVerifiedLinkText}</Link>
    </p>
  </FormTile>
)

IDPLinkEmail.displayName = 'IDPLinkEmail'

export default IDPLinkEmail
