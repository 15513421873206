import React, { Component } from 'react'
import { TextField, Button } from '@deque/cauldron-react'
import FormTile from './FormTile'
import { EMAIL_LOCAL_STORAGE_KEY } from '../constants'

interface Props {
  resourcePath: string
  submitText: string
  loginUrl: string
  usernameText: string
  cancelText: string
  headingText: string
  error?: string
}

export default class ForgotPassword extends Component<Props> {
  private email: HTMLInputElement | null = null

  private onEmailChange = (value: string): void => {
    localStorage.setItem(EMAIL_LOCAL_STORAGE_KEY, value)
  }

  private setEmailRef = (el: HTMLInputElement): void => {
    this.email = el
  }

  public componentDidMount(): void {
    if (this.props.error && this.email) {
      this.email.focus()
    }
  }

  public render(): React.ReactElement {
    const {
      resourcePath,
      usernameText,
      submitText,
      headingText,
      error = null,
      cancelText,
      loginUrl
    } = this.props

    return (
      <FormTile
        logo={<img src={`${resourcePath}/img/deque.svg`} alt="Deque" />}
        heading={<h1>{headingText}</h1>}
      >
        <TextField
          id="username"
          name="username"
          label={usernameText}
          error={error}
          fieldRef={this.setEmailRef}
          onChange={this.onEmailChange}
          autoComplete="username"
        />
        <div className="App__tile-actions">
          <Button type="submit">{submitText}</Button>
          <a
            href={loginUrl}
            className="Button--secondary App__tile-actions-cancel"
          >
            {cancelText}
          </a>
        </div>
      </FormTile>
    )
  }
}
