import React from 'react'
import { Link } from '@deque/cauldron-react'
import FormTile from './FormTile'
import withClients, { IsClientProps } from './withClients'
import * as analytics from '../analytics'

interface Props extends IsClientProps {
  headingText: string
  instructionsText: string
  questionText: string
  linkText: string
  resourcePath: string
  linkHref: string
}

const VerifyEmail: React.ComponentType<Props> = ({
  isAxe,
  headingText,
  instructionsText,
  questionText,
  linkText,
  linkHref,
  resourcePath
}) => {
  const handleClick = (): void => {
    analytics.verifyEmailClick({
      isAxe
    })
  }
  return (
    <FormTile
      logo={<img src={`${resourcePath}/img/deque.svg`} alt="Deque" />}
      heading={<h1>{headingText}</h1>}
    >
      <p>{instructionsText}</p>
      <p>{questionText}</p>
      <Link href={linkHref} onClick={handleClick}>
        {linkText}
      </Link>
    </FormTile>
  )
}

VerifyEmail.displayName = 'VerifyEmail'

export default withClients(VerifyEmail)
