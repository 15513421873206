import React, { Component } from 'react'
import { TextField, Button, Link } from '@deque/cauldron-react'
import FormTile from './FormTile'
import EmailField from '../EmailField'

interface Props {
  resourcePath: string
  headingText: string
  firstNameLabel: string
  firstNameValue?: string
  lastNameLabel: string
  lastNameValue?: string
  emailLabel: string
  emailValue?: string
  passwordLabel: string
  confirmPasswordLabel: string
  backToLoginText: string
  backToLoginHref: string
  submitText: string
  firstNameErrorText: string
  lastNameErrorText: string
  emailErrorText: string
  invalidEmailErrorText: string
  missingPasswordErrorText: string
  passwordMatchErrorText: string
  usernameExistsErrorText: string
  emailExistsErrorText: string
  passwordConfirmMatchErrorText: string
  errors?: string[]
}

export default class Register extends Component<Props> {
  private firstName: HTMLInputElement | null = null
  private lastName: HTMLInputElement | null = null
  private email: HTMLInputElement | null = null
  private password: HTMLInputElement | null = null
  private confirmPassword: HTMLInputElement | null = null

  private setFirstName = (el: HTMLInputElement): void => {
    this.firstName = el
  }

  private setLastName = (el: HTMLInputElement): void => {
    this.lastName = el
  }

  private setEmail = (el: HTMLInputElement): void => {
    this.email = el
  }

  private setPassword = (el: HTMLInputElement): void => {
    this.password = el
  }

  private setConfirmPassword = (el: HTMLInputElement): void => {
    this.confirmPassword = el
  }

  public componentDidMount(): void {
    const { errors } = this.props

    if (!errors || !errors.length) {
      return
    }

    const inputs = [
      this.firstName,
      this.lastName,
      this.email,
      this.password,
      this.confirmPassword
    ]
    const firstInvalid = inputs.find(
      input => input && input.getAttribute('aria-invalid') === 'true'
    )

    if (!firstInvalid) {
      return
    }

    // focus the first erroneous input
    firstInvalid.focus()
  }

  public render(): React.ReactElement {
    const {
      resourcePath,
      headingText,
      firstNameLabel,
      firstNameValue,
      lastNameLabel,
      lastNameValue,
      emailLabel,
      emailValue,
      passwordLabel,
      confirmPasswordLabel,
      backToLoginText,
      backToLoginHref,
      submitText,
      firstNameErrorText,
      lastNameErrorText,
      emailErrorText,
      invalidEmailErrorText,
      missingPasswordErrorText,
      passwordMatchErrorText,
      usernameExistsErrorText,
      emailExistsErrorText,
      passwordConfirmMatchErrorText,
      errors
    } = this.props

    const confirmPasswordError =
      errors &&
      (errors.includes(passwordMatchErrorText) ||
        errors.includes(passwordConfirmMatchErrorText)) &&
      passwordMatchErrorText

    return (
      <FormTile
        logo={<img src={`${resourcePath}/img/deque.svg`} alt="Deque" />}
        heading={<h1>{headingText}</h1>}
      >
        <TextField
          required
          type="text"
          id="firstName"
          name="firstName"
          defaultValue={firstNameValue}
          label={firstNameLabel}
          fieldRef={this.setFirstName}
          error={
            errors && errors.includes(firstNameErrorText) && firstNameErrorText
          }
          autoComplete="given-name"
        />
        <TextField
          required
          type="text"
          id="lastName"
          name="lastName"
          defaultValue={lastNameValue}
          label={lastNameLabel}
          fieldRef={this.setLastName}
          error={
            errors && errors.includes(lastNameErrorText) && lastNameErrorText
          }
          autoComplete="family-name"
        />
        <EmailField
          value={emailValue}
          label={emailLabel}
          error={
            (errors && errors.includes(emailErrorText) && emailErrorText) ||
            (errors &&
              errors.includes(invalidEmailErrorText) &&
              invalidEmailErrorText) ||
            (errors &&
              (errors.includes(emailExistsErrorText) ||
                errors.includes(usernameExistsErrorText)) &&
              emailExistsErrorText)
          }
          fieldRef={this.setEmail}
        />
        <TextField
          required
          type="password"
          id="password"
          name="password"
          label={passwordLabel}
          fieldRef={this.setPassword}
          error={
            (errors &&
              errors.includes(missingPasswordErrorText) &&
              missingPasswordErrorText) ||
            confirmPasswordError
          }
        />
        <TextField
          required
          type="password"
          id="password-confirm"
          name="password-confirm"
          label={confirmPasswordLabel}
          fieldRef={this.setConfirmPassword}
          error={confirmPasswordError}
        />
        <div>
          <Link href={backToLoginHref}>{backToLoginText}</Link>
        </div>
        <div>
          <Button name="login" type="submit">
            {submitText}
          </Button>
        </div>
      </FormTile>
    )
  }
}
