import React, { Component, Fragment } from 'react'
import { TextField, Button } from '@deque/cauldron-react'
import FormTile from './FormTile'
import withClients, { IsClientProps } from './withClients'
import * as analytics from '../analytics'

interface Props extends IsClientProps {
  error?: string
  resourcePath: string
  submitText: string
  headingText: string
  passwordNewText: string
  passwordConfirmText: string
  paragraphText: string
}

class UpdatePassword extends Component<Props> {
  static defaultProps = {
    error: null
  }

  private passwordNew: HTMLInputElement | null = null

  private setPasswordNew = (el: HTMLInputElement): void => {
    this.passwordNew = el
  }

  public componentDidMount(): void {
    if (this.props.error && this.passwordNew) {
      this.passwordNew.focus()
    }
  }

  private handleClick = (): void => {
    analytics.updatePasswordClick({
      isAxe: this.props.isAxe
    })
  }

  public render(): React.ReactElement {
    const {
      resourcePath,
      submitText,
      headingText,
      error,
      passwordNewText,
      passwordConfirmText,
      paragraphText
    } = this.props

    return (
      <FormTile
        logo={<img src={`${resourcePath}/img/deque.svg`} alt="Deque" />}
        heading={
          <Fragment>
            <h1>{headingText}</h1>
            <p>
              <em>{paragraphText}</em>
            </p>
          </Fragment>
        }
      >
        <TextField
          type="password"
          id="password-new"
          name="password-new"
          label={passwordNewText}
          error={error}
          fieldRef={this.setPasswordNew}
          autoComplete="off"
        />
        <TextField
          type="password"
          id="password-confirm"
          name="password-confirm"
          label={passwordConfirmText}
          autoComplete="off"
        />
        <Button type="submit" onClick={this.handleClick}>
          {submitText}
        </Button>
      </FormTile>
    )
  }
}

export default withClients(UpdatePassword)
