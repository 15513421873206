import React from 'react'
import classNames from 'classnames'
import './FormTile.css'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  logo: React.ReactNode
  heading: React.ReactNode
  children: React.ReactNode
}

const FormTile: React.ComponentType<Props> = ({
  logo,
  heading,
  children,
  className,
  ...other
}) => (
  <div className={classNames('App', className)} {...other}>
    <div className="App__tile">
      <div className="App__tile-logo">{logo}</div>
      <div className="App__tile-heading">{heading}</div>
      <div className="App__tile-fields">{children}</div>
    </div>
  </div>
)

FormTile.displayName = 'FormTile'

export default FormTile
