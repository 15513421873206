// NOTE: axe pro relies on `EMAIL_LOCAL_STORAGE_KEY`.
// If that string needs to be updated, update in axe pro too
export const EMAIL_LOCAL_STORAGE_KEY = 'EMAIL_LOCAL_STORAGE_KEY'
export const REDIRECT_URI_LOCAL_STORAGE_KEY = 'REDIRECT_URI_LOCAL_STORAGE_KEY'
export const REDIRECT_NOTICE_TIMEOUT = 5000
export const ALLOWED_SELF_REGISTRATION = ['university']

// If the underlying environment variables are not set, Keycloak will not expand these properties.
export const UNSET_DEPLOY_ENV = '${env.DEPLOY_ENV}'
export const UNSET_AXE_SERVER_URL = '${env.AXE_SERVER_URL}'
export const UNSET_IS_ON_PREM = '${env.IS_ON_PREM}'
export const UNSET_MOBILE_CLIENT_ID = '${env.MOBILE_CLIENT_ID}'
export const UNSET_DQU_CLIENT_ID = '${env.DQU_CLIENT_ID}'
export const UNSET_AXE_CLIENT_ID = '${env.AXE_CLIENT_ID}'
export const UNSET_THEME_CLIENT_ID = '${env.THEME_CLIENT_ID}'
export const UNSET_SUPPORT_DOMAIN = '${env.SUPPORT_DOMAIN}'

// Default public client IDs.
export const DEFAULT_MOBILE_CLIENT_ID = 'deque-mobile-attest-ui'
export const DEFAULT_DQU_CLIENT_ID = 'university'
export const DEFAULT_AXE_CLIENT_ID = 'axepro-public'
export const DEFAULT_THEME_CLIENT_ID = 'theme-public'
export const DEFAULT_SUPPORT_DOMAIN = 'support.deque.com'
// The on prem client ID is not configurable.
// See https://github.com/dequelabs/walnut/issues/1702.
export const AXE_ON_PREM_CLIENT_ID = 'axepro_onprem'
export const ADMIN_CONSOLE_CLIENT_ID = 'security-admin-console'
