import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import '@deque/cauldron-styles/dist/index.css'
import '@deque/cauldron-react/lib/cauldron.css'
import './index.css'

interface Props {
  title: string
  children: React.ReactNode
}

/**
 * Serves as a common wrapper for both the login and account pages
 */
const Wrapper: React.ComponentType<Props> = ({ title, children }) => {
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Fragment>
  )
}

Wrapper.displayName = 'Wrapper'

export default Wrapper
