import React from 'react'
import FormTile from './FormTile'
import { Button } from '@deque/cauldron-react'
import withClients, { IsClientProps } from './withClients'
import * as analytics from '../analytics'
import './IDPLinkConfirm.css'

interface Props extends IsClientProps {
  headingText: string
  resourcePath: string
  updateProfileText: string
  linkAccountText: string
  linkAccountInfoText: string
}

const IDPLinkConfirm: React.ComponentType<Props> = ({
  isAxe,
  headingText,
  resourcePath,
  updateProfileText,
  linkAccountText,
  linkAccountInfoText
}) => {
  const handleClick = () => {
    analytics.linkExistingEmailClick({
      isAxe
    })
  }
  return (
    <FormTile
      logo={<img src={`${resourcePath}/img/deque.svg`} alt="Deque" />}
      heading={<h1>{headingText}</h1>}
    >
      <div className="IDPConfirm__action">
        <p>{linkAccountInfoText}</p>
        <Button
          name="submitAction"
          value="updateProfile"
          id="updateProfile"
          type="submit"
          variant="secondary"
        >
          {updateProfileText}
        </Button>
      </div>
      <div className="IDPConfirm__action">
        <Button
          name="submitAction"
          value="linkAccount"
          id="linkAccount"
          type="submit"
          variant="secondary"
          onClick={handleClick}
        >
          {linkAccountText}
        </Button>
      </div>
    </FormTile>
  )
}

IDPLinkConfirm.displayName = 'IDPLinkConfirm'

export default withClients(IDPLinkConfirm)
