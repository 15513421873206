import amplitudeJs from 'amplitude-js'

export enum AmplitudeEvents {
  // Connect with Google
  GOOGLE_CONNECT = 'auth:connect:google',
  // Connect with GitHub
  GITHUB_CONNECT = 'auth:connect:github',
  // Signup with Deque
  DEQUE_SIGNUP = 'auth:signup',
  // Next
  LOGIN_NEXT_CLICK = 'auth:next',
  // Submit
  LOGIN_SUBMIT_CLICK = 'auth:submit',
  // Forgot password
  FORGOT_PASSWORD_CLICK = 'auth:forgot-password',
  LOGIN_VIEW = 'auth:view',
  UPDATE_PASSWORD_CLICK = 'auth:update-password',
  SETUP_TWO_FACTOR_CLICK = 'auth:setup-two-factor',
  TWO_FACTOR_CLICK = 'auth:two-factor',
  UPDATE_PROFILE_CLICK = 'auth:update-profile',
  VERIFY_EMAIL_CLICK = 'auth:verify-email',
  LINK_EXISTING_EMAIL_CLICK = 'auth:link-existing-email'
}

/**
 * Amplitude client.
 *
 * Exported for testing. This should not be used outside of this module.
 */

export const amplitude = amplitudeJs.getInstance()

interface InitParams {
  amplitudeApiKey?: string
}

let didInitializeAmplitude = false

/**
 * Optionally send the given `event` with `data`, invoking `callback` when done.
 *
 * If the Amplitude client has not been initialized, calling this will simply noop, otherwise the following error is thrown:
 *
 * ```
 * [Amplitude] Invalid apiKey. Please set a valid apiKey with init() before calling setGroup()
 * ```
 *
 * @param event
 * @param data
 * @param callback
 */

const sendAmplitudeEvent = (event: AmplitudeEvents): void => {
  if (!didInitializeAmplitude) {
    return
  }

  amplitude.logEvent(event)
}

/**
 * Initialize the analytics client.
 */

export const init = ({ amplitudeApiKey }: InitParams = {}): void => {
  // Only initialize Amplitude when provided an API key.
  // This enables us to work locally without sending analytics.
  if (amplitudeApiKey) {
    amplitude.init(amplitudeApiKey, undefined, {
      includeUtm: true,
      saveParamsReferrerOncePerSession: false,
      includeGclid: true,
      includeReferrer: true,
      secureCookie: true,
      sameSiteCookie: 'Lax'
    })
    didInitializeAmplitude = true
  } else {
    // Reset (for tests).
    didInitializeAmplitude = false
  }
}

interface EventData {
  isAxe: boolean
}

const eventFactory = (event: AmplitudeEvents) => ({
  isAxe
}: EventData): void => {
  if (!isAxe) {
    return
  }

  sendAmplitudeEvent(event)
}

export const googleConnect = eventFactory(AmplitudeEvents.GOOGLE_CONNECT)
export const githubConnect = eventFactory(AmplitudeEvents.GITHUB_CONNECT)
export const dequeSignup = eventFactory(AmplitudeEvents.DEQUE_SIGNUP)
export const loginNextClick = eventFactory(AmplitudeEvents.LOGIN_NEXT_CLICK)
export const loginSubmitClick = eventFactory(AmplitudeEvents.LOGIN_SUBMIT_CLICK)
export const forgotPasswordClick = eventFactory(
  AmplitudeEvents.FORGOT_PASSWORD_CLICK
)
export const loginView = eventFactory(AmplitudeEvents.LOGIN_VIEW)
export const updatePasswordClick = eventFactory(
  AmplitudeEvents.UPDATE_PASSWORD_CLICK
)
export const setupTwoFactorClick = eventFactory(
  AmplitudeEvents.SETUP_TWO_FACTOR_CLICK
)
export const twoFactorClick = eventFactory(AmplitudeEvents.TWO_FACTOR_CLICK)
export const updateProfileClick = eventFactory(
  AmplitudeEvents.UPDATE_PROFILE_CLICK
)
export const verifyEmailClick = eventFactory(AmplitudeEvents.VERIFY_EMAIL_CLICK)
export const linkExistingEmailClick = eventFactory(
  AmplitudeEvents.LINK_EXISTING_EMAIL_CLICK
)
