import React from 'react'
import FormTile from './FormTile'
import './Info.css'

interface Props {
  resourcePath: string
  heading: string
  actionHref: string
  actionText: string
  verifyEmailText: string
  verifyEmailMessage: string
  requiredActions?: string[]
}

const Info: React.ComponentType<Props> = ({
  resourcePath,
  heading,
  actionHref,
  actionText,
  verifyEmailText,
  verifyEmailMessage,
  requiredActions = []
}) => {
  const isVerifyEmailSingleAction =
    requiredActions.length === 1 && requiredActions[0] === verifyEmailText

  return (
    <FormTile
      logo={<img src={`${resourcePath}/img/deque.svg`} alt="Deque" />}
      heading={
        <h1 className="Info__heading">
          {isVerifyEmailSingleAction ? verifyEmailMessage : heading}
        </h1>
      }
    >
      {isVerifyEmailSingleAction ? (
        <div className="Info__action">
          <a className="Button--primary" href={actionHref}>
            {verifyEmailText}
          </a>
        </div>
      ) : (
        <>
          {!!requiredActions.length && (
            <ul className="Info__list">
              {requiredActions.map(action => (
                <li key={action}>{action}</li>
              ))}
            </ul>
          )}
          {!!actionHref && !!actionText && (
            <div className="Info__action">
              <a className="Button--primary" href={actionHref}>
                {actionText}
              </a>
            </div>
          )}
        </>
      )}
    </FormTile>
  )
}

Info.displayName = 'Info'

export default Info
